// Transactions
export const FETCH_TRANSACTION_LEGS = 'FETCH_TRANSACTION_LEGS';
export const RECEIVED_TRANSACTION_LEGS = 'RECEIVED_TRANSACTION_LEGS';
export const FETCH_TRANSACTION_LEGS_ERROR = 'FETCH_TRANSACTION_LEGS_ERROR';

// Dimensions
export const FETCH_ALL_STORE = 'FETCH_ALL_STORE';
export const RECEIVED_ALL_STORES = 'RECEIVED_ALL_STORES';
export const FETCH_ALL_STORES_ERROR = 'FETCH_ALL_STORES_ERROR';

export const FETCH_RETAILERS = 'FETCH_RETAILERS';
export const RECEIVED_RETAILERS = 'RECEIVED_RETAILERS';
export const FETCH_RETAILERS_ERROR = 'FETCH_RETAILER_ERROR';

export const FETCH_STORE_NAMES = 'FETCH_STORE_NAMES';
export const RECEIVED_STORE_NAMES = 'RECEIVED_STORE_NAMES';
export const FETCH_STORE_NAMES_ERROR = 'FETCH_STORE_NAMES_ERROR';

export const FETCH_TRANSACTION_TYPES = 'FETCH_TRANSACTION_TYPES';
export const RECEIVED_TRANSACTION_TYPES = 'RECEIVED_TRANSACTION_TYPES';
export const FETCH_TRANSACTION_TYPES_ERROR = 'FETCH_TRANSACTION_TYPES_ERROR';

export const FETCH_TRANSACTION_OUTCOMES = 'FETCH_TRANSACTION_OUTCOMES';
export const RECEIVED_TRANSACTION_OUTCOMES = 'RECEIVED_TRANSACTION_OUTCOMES';
export const FETCH_TRANSACTION_OUTCOMES_ERROR =
  'FETCH_TRANSACTION_OUTCOMES_ERROR';

// Search Criteria
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const TOGGLE_CLEAR_FILTERS = 'TOGGLE_CLEAR_FILTERS';
export const SET_DATE_RANGE_VALUE = 'SET_DATE_RANGE_VALUE';
export const SET_TIME_RANGE_VALUE = 'SET_TIME_RANGE_VALUE';
export const SET_HEADER_DROPDOWN_VALUE = 'SET_HEADER_DROPDOWN_VALUE';
export const SET_HEADER_SEARCH_VALUE = 'SET_HEADER_SEARCH_VALUE';
export const SET_ACTIVE_STORE_VALUE = 'SET_ACTIVE_STORE_VALUE';

export const FETCH_LOOKUPS = 'FETCH_LOOKUPS';
export const RECEIVED_LOOKUPS = 'RECEIVED_LOOKUPS';
export const FETCH_LOOKUPS_ERROR = 'FETCH_LOOKUPS_ERROR';

export const SAVE_SEARCH_CRITERIA = 'SAVE_SEARCH_CRITERIA';
export const SAVING_SEARCH_CRITERIA = 'SAVING_SEARCH_CRITERIA';
export const SAVE_SEARCH_CRITERIA_ERROR = 'SAVE_SEARCH_CRITERIA_ERROR';

export const FETCH_SAVED_SEARCHES = 'FETCH_SAVED_SEARCHES';
export const RECEIVED_SAVED_SEARCHES = 'RECEIVED_SAVED_SEARCHES';
export const FETCH_SAVED_SEARCHES_ERROR = 'FETCH_SAVED_SEARCHES_ERROR';

export const FETCH_SEARCH_CRITERIA = 'FETCH_SEARCH_CRITERIA';
export const RECEIVED_SEARCH_CRITERIA = 'RECEIVED_SEARCH_CRITERIA';
export const FETCH_SEARCH_CRITERIA_ERROR = 'FETCH_SEARCH_CRITERIA_ERROR';

export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_FILTER_TAB = 'SET_FILTER_TAB';

export const SET_DAY_TAB_TABLE = 'SET_DAY_TAB_TABLE';
export const SET_RECON_DAY_TAB_TABLE = 'SET_RECON_DAY_TAB_TABLE';

// Export Data
export const SET_EXPORT_FILTERS = 'SET_EXPORT_FILTERS';

// Graph Data
export const FETCH_GRAPH_DATA = 'FETCH_GRAPH_DATA';
export const RECEIVED_GRAPH_DATA = 'RECEIVED_GRAPH_DATA';
export const FETCH_GRAPH_DATA_ERROR = 'FETCH_GRAPH_DATA_ERROR';

// Metric Data
export const FETCH_METRICS_DATA = 'FETCH_METRICS_DATA';
export const RECEIVED_METRICS_DATA = 'RECEIVED_METRICS_DATA';
export const FETCH_METRICS_DATA_ERROR = 'FETCH_METRICS_DATA_ERROR';

// Helpers
export const SET_INACTIVE_TERMINAL_COUNT = 'SET_INACTIVE_TERMINAL_COUNT';
export const FETCH_STORE_IDS = 'FETCH_STORE_IDS';
export const RECEIVED_STORE_IDS = 'RECEIVED_STORE_IDS';
export const FETCH_STORE_IDS_ERROR = 'FETCH_STORE_IDS_ERROR';

// Notifications
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const RECEIVED_NOTIFICATIONS = 'RECEIVED_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

//User actions
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const RECEIVED_USER_INFO = 'RECEIVED_USER_INFO';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

// Reports
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const RECEIVED_REPORTS = 'RECEIVED_REPORTS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';
export const SET_REPORTS_DATE_RANGE_VALUE = 'SET_REPORTS_DATE_RANGE_VALUE';

export const FETCH_REPORT_DOWNLOAD = 'FETCH_REPORT_DOWNLOAD';
export const RECEIVED_REPORT_DOWNLOAD = 'RECEIVED_REPORT_DOWNLOAD';
export const FETCH_REPORT_DOWNLOAD_ERROR = 'FETCH_REPORT_DOWNLOAD_ERROR';

// Recon
export const CLEAR_RECON = 'CLEAR_RECON';
export const FETCH_RECON = 'FETCH_RECON';
export const RECEIVED_RECON = 'RECEIVED_RECON';
export const FETCH_RECON_ERROR = 'FETCH_RECON_ERROR';
export const SET_RECON_DATE_RANGE_VALUE = 'SET_RECON_DATE_RANGE_VALUE';
export const SET_RECON_ROW_DATA = 'SET_RECON_ROW_DATA';
export const SET_RECON_DAY_DATA = 'SET_RECON_DAY_DATA';
export const SET_RECON_STORE_ID = 'SET_RECON_STORE_ID';
export const SET_RECON_FILTER_DATE_RANGE_VALUE = 'SET_RECON_FILTER_DATE_RANGE_VALUE';
export const CLEAR_RECON_ROW_DATA = 'CLEAR_RECON_ROW_DATA';