// External Libraries
import axios from "axios";
import axiosRetry from "axios-retry";

/*********************
Recon API URL
**********************/
let BASE_URL = window?._env_?.RECON_EXT_API_URI ? window._env_.RECON_EXT_API_URI : "https://dev-recon-api.imbeko.live/api/v1";

if (!BASE_URL) {
  throw new Error('RECON EXT API URI not set');
}

/** **************************
Create custom instance of Axios
**************************** */
const reconApi = axios.create({
  baseURL: BASE_URL,
});

axiosRetry(axios, { retries: 3 });

export default reconApi;
