import { SET_SEARCH_CRITERIA } from '../actions/actionsTypes';

const searchCriteriaReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_CRITERIA:
      return action.data;
    default:
      return state;
  }
};

export default searchCriteriaReducer;