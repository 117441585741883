import axios from 'axios';
export const updateUserAttributes = async (userId, attributes, token) => {
  const url = `${window?._env_?.KEYCLOAK_URI}/admin/realms/${window?._env_?.KEYCLOAK_REALM}/users/${userId}`;
  const data = {
    attributes: {
      ...attributes,
    },
  };
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.put(url, data, { headers });
    return data;
  } catch (error) {
    console.error('Failed to update user attributes:', error);
    throw error;
  }
};
