// External Libraries
import axios from 'axios';
import axiosRetry from 'axios-retry';

/*********************
Base API URL
**********************/
let BASE_URL = window?._env_?.API_URI
  ? window._env_.API_URI
  : 'https://dev-transpector-api.imbeko.live/api/v1';

if (!BASE_URL) {
  throw new Error('API URI not set');
}

/** **************************
Create custom instance of Axios
**************************** */
const api = axios.create({
  baseURL: BASE_URL,
});

axiosRetry(axios, { retries: 3 });

export default api;
