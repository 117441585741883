import {
  FETCH_SAVED_SEARCHES,
  RECEIVED_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_ERROR,
} from '../actions/actionsTypes';

const initialState = {
  data: [],
  isFetching: false,
  isError: false,
};

const savedSearchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVED_SEARCHES:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        data: [],
      });
    case RECEIVED_SAVED_SEARCHES:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        data: action.data,
      });
    case FETCH_SAVED_SEARCHES_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        data: [],
      });
    default:
      return state;
  }
};

export default savedSearchesReducer;
