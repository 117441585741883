import { FETCH_ALL_STORE, RECEIVED_ALL_STORES, FETCH_ALL_STORES_ERROR } from '../actions/actionsTypes';

const initialState = {
  data: {},
  isFetching: false,
  isError: false
};

const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_STORE:
      return Object.assign({}, state, {
        isFetching: true,
        data: {},
        isError: false
      });
    case RECEIVED_ALL_STORES:
      return Object.assign({}, state, {
        data: action.data,
        isFetching: false,
        isError: false
      });
    case FETCH_ALL_STORES_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false
      });
    default:
      return state;
  }
};

export default storesReducer;