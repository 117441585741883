import { SET_EXPORT_FILTERS } from '../actions/actionsTypes';

const initialState = {
  filters: '',
  validForSave: false,
};

const exportDataReducer = (state = initialState, action) => {
  if (action.type === SET_EXPORT_FILTERS) {
    return {
      filters: action.data.filters,
      validForSave: action.data.validForSave,
    };
  }
  return state;
};

export default exportDataReducer;
