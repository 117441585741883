import {
  SET_RECON_ROW_DATA,
  CLEAR_RECON_ROW_DATA,
  SET_RECON_DAY_DATA,
  SET_RECON_FILTER_DATE_RANGE_VALUE,
  SET_RECON_STORE_ID,
} from '../actions/actionsTypes';
import moment from "moment";

const initialState = {
  filter: {
    startDate: moment(),
    endDate: moment(),
  },
  analysis: {
    storeId: '',
    date: moment(),
  },
  rowData: {},
  days: '1',
  cleared: false,
};

const reconReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECON_ROW_DATA:
      return Object.assign({}, state, {
        rowData: action.data,
        cleared: false,
      });
    case SET_RECON_DAY_DATA:
      return Object.assign({}, state, {
        days: action.data,
        filter: {
          startDate: action.data === '1'
              ? moment()
              : moment().subtract(Number(action.data), "days"),
          endDate: moment(),
        },
        cleared: false,
      });
    case SET_RECON_FILTER_DATE_RANGE_VALUE:
      return Object.assign({}, state, {
        filter: {
          startDate: action.data[0],
          endDate: action.data[1],
        },
        cleared: false,
      });
    case SET_RECON_STORE_ID:
      return Object.assign({}, state, {
        analysis : {
          storeId: action.data[0],
          date: action.data[1],
        },
        cleared: false,
      });
    case CLEAR_RECON_ROW_DATA:
      return Object.assign({}, state, {
        rowData: {},
        cleared: true,
      });
    default:
      return state;
  }
};

export default reconReducer;
