import { FETCH_SEARCH_CRITERIA, RECEIVED_SEARCH_CRITERIA, FETCH_SEARCH_CRITERIA_ERROR } from '../actions/actionsTypes';
import { actions } from 'react-redux-toastr';

const initialState = {
  data: {},
  isFetching: false,
  isError: false
};

const savedSearchCriteriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        data: {}
      });
    case RECEIVED_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        data: actions.data,
      });
    case FETCH_SEARCH_CRITERIA_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        data: {}
      });
    default:
      return state;
  }
};

export default savedSearchCriteriaReducer;