import { toastr } from 'react-redux-toastr';
import api from '../helpers/api';
import store from '../helpers/store';
import {
  FETCH_USER_INFO,
  RECEIVED_USER_INFO,
  FETCH_USER_INFO_ERROR
} from './actionsTypes';

export const fetch_user_info = () => {
  return {
    type: FETCH_USER_INFO
  };
};

export const receive_user_info = userInfo => {
  return {
    type: RECEIVED_USER_INFO,
    data: userInfo
  };
};

export const receive_user_info_error = () => {
  return {
    type: FETCH_USER_INFO_ERROR
  };
};

export const thunk_fetch_user_info = () => {
  store.dispatch(fetch_user_info());
  return async dispatch => {
    try {
      const data = await api.get('/userinfo');
      dispatch(receive_user_info(data));
    } catch (error) {
      dispatch(receive_user_info_error());
      toastr.error('Network Error', 'User info could not be retrieved.');
    }
  };
};

export const updatePassword = (callback) => {
  api
    .put(`/update-user-password`)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      toastr.error(
        'Network Error',
        'Something went wrong changing your password.'
      );
    });
};

export const configureOtp = (callback) => {
  api
      .post(`/add-opt`)
      .then(response => {
        callback(response);
      })
      .catch(error => {
        toastr.error(
            'Network Error',
            'Something went wrong with sending the OTP configuration link.'
        );
      });
};

export const removeOtp = (id, callback) => {
  api
      .delete(`/remove-credential?id=${id}&name=otp`)
      .then(response => {
        callback(response);
      })
      .catch(error => {
        toastr.error(
            'Network Error',
            'Something went wrong removing OTP.'
        );
      });
};

export const logout = callback => {
  api
    .get(`/logout`)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
