import { SET_INACTIVE_TERMINAL_COUNT } from '../actions/actionsTypes';

const initialState = {
  count: 0,
  updated: false
};

const inactiveTerminalCountReducer = (state = initialState, action) => {
  if (action.type === SET_INACTIVE_TERMINAL_COUNT) {
    return {
      count: action.data,
      updated: true
    };
  }
  return state;
};

export default inactiveTerminalCountReducer;