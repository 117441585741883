import React from 'react';
import Logo from '../../static/images/logo-tj-vertical.svg';
import Spinner from '../../static/images/loader.svg';

const Loader = ({ loaderText = '', size = 'large', top = false }) => {
  return (
    <div className={`loader ${size} ${top ? 'top' : ''}`}>
      <h1 className="heading">
        {!loaderText && <img alt="Transaction Junction logo" src={Logo} />}
        {loaderText}
      </h1>
      <p className="sub-heading">
        <img alt="Spinner" src={Spinner} />
      </p>
    </div>
  );
};

export default Loader;
