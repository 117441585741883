import { SET_ACTIVE_STORE_VALUE } from '../actions/actionsTypes';

const initialState = null;

const activeStoreReducer = (state = initialState, action) => {
  if (action.type === SET_ACTIVE_STORE_VALUE) {
    return action.data;
  }
  return state;
};

export default activeStoreReducer;
