import {
  SET_HEADER_DROPDOWN_VALUE,
  SET_HEADER_SEARCH_VALUE,
  SET_DATE_RANGE_VALUE,
  SET_REPORTS_DATE_RANGE_VALUE,
  SET_RECON_DATE_RANGE_VALUE,
  CLEAR_ALL_FILTERS,
  SET_FILTER_TAB,
  SET_DAY_TAB_TABLE,
  TOGGLE_CLEAR_FILTERS,
  SET_RECON_DAY_TAB_TABLE,
  SET_TIME_RANGE_VALUE,
} from '../actions/actionsTypes';

import moment from 'moment';

const initialState = {
  headerDropdownValue: '',
  headerSearchValue: '',
  startTime: '00:00:00',
  endTime: moment().format('HH:mm:ss'),
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  cleared: false,
  reportStartdate: moment(),
  reportEnddate: moment(),
  reconStartdate: moment().subtract(1, "months"),
  reconEnddate: moment(),
  tabValue: 0,
  dayTabTableValue: 4,
  reconDayTabTableValue: 2,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_DROPDOWN_VALUE:
      return Object.assign({}, state, {
        headerDropdownValue: action.data,
        cleared: false,
      });
    case SET_HEADER_SEARCH_VALUE:
      return Object.assign({}, state, {
        headerSearchValue: action.data,
        cleared: false,
      });
    case SET_DATE_RANGE_VALUE:
      return Object.assign({}, state, {
        startDate: moment(action.data[0]).format('YYYY-MM-DD'),
        endDate: moment(action.data[1]).format('YYYY-MM-DD'),
        cleared: false,
      });
    case SET_TIME_RANGE_VALUE:
      return Object.assign({}, state, {
        startTime: action.data[0],
        endTime: action.data[1],
        cleared: false,
      });
    case SET_REPORTS_DATE_RANGE_VALUE:
      return Object.assign({}, state, {
        reportStartdate: action.data[0],
        reportEnddate: action.data[1],
        cleared: false,
      });
    case SET_RECON_DATE_RANGE_VALUE:
      return Object.assign({}, state, {
        reconStartdate: action.data[0],
        reconEnddate: action.data[1],
        cleared: false,
      });
    case SET_FILTER_TAB:
      return Object.assign({}, state, {
        tabValue: action.data,
        cleared: false,
      });
    case SET_DAY_TAB_TABLE:
      return Object.assign({}, state, {
        dayTabTableValue: action.data,
        cleared: false,
      });
    case SET_RECON_DAY_TAB_TABLE:
      return Object.assign({}, state, {
        reconDayTabTableValue: action.data,
      });
    case CLEAR_ALL_FILTERS:
      return Object.assign({}, state, {
        headerDropdownValue: '',
        headerSearchValue: '',
        startTime: '00:00:00',
        endTime: moment().format('HH:mm:ss'),
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        cleared: true,
        reportStartdate: moment(),
        reportEnddate: moment(),
        dayTabTableValue: 4,
      });
    case TOGGLE_CLEAR_FILTERS:
      return Object.assign({}, state, {
        ...state,
        cleared: !state.cleared,
      });
    default:
      return state;
  }
};

export default filterReducer;
