// External Libraries
import axios from "axios";
import axiosRetry from "axios-retry";

/*********************
Recon API URL
**********************/
let BASE_URL = window?._env_?.RECON_2_API_URI ? window._env_.RECON_2_API_URI : "https://dev-recon-api-2.imbeko.live/api/v2/";

if (!BASE_URL) {
  throw new Error('RECON 2 API URI not set');
}

/** **************************
Create custom instance of Axios
**************************** */
const recon2Api = axios.create({
  baseURL: BASE_URL,
});

axiosRetry(axios, { retries: 3 });

export default recon2Api;
