import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { useIdleTimer } from 'react-idle-timer';

// Roboto Font
import RobotoWoff2 from './fonts/roboto.woff2';

// Router Setup
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
  Redirect,
} from 'react-router-dom';

// Material UI Setup
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import _ from 'lodash';

import componentLoader from '../helpers/componentLoader.js';

// Layout Components
import Loader from './layout/Loader';
import { thunk_fetch_user_info } from '../actions/userActions';
import HelpPage from './help/HelpPage';

import ReactGA from 'react-ga4';

// Lazy Load for smaller bundle sizes
const Header = lazy(() => componentLoader(() => import('./layout/Header'), 5));
const Footer = lazy(() => componentLoader(() => import('./layout/Footer'), 5));
const Navigation = lazy(() =>
  componentLoader(() => import('./layout/Navigation'), 5)
);
const TransactionsPage = lazy(() =>
  componentLoader(() => import('./transactions/TransactionsPage'), 5)
);
const NotificationPage = lazy(() =>
  componentLoader(() => import('./layout/NotificationPage'), 5)
);
const TransactionReports = lazy(() =>
  componentLoader(() => import('./layout/TransactionReports'), 5)
);
const ReconReports = lazy(() =>
  componentLoader(() => import('./layout/ReconReports'), 5)
);
const NetworkError = lazy(() =>
  componentLoader(() => import('./inputs/NetworkError'), 5)
);
const Page404 = lazy(() =>
  componentLoader(() => import('./layout/Page404'), 5)
);
const DashboardRoute = lazy(() =>
  componentLoader(() => import('./dashboards/DashboardRoute'), 5)
);
const ReconciliationPage = lazy(() =>
  componentLoader(() => import('./recon/ReconciliationPage'), 5)
);
const ReconciliationDetail = lazy(() =>
  componentLoader(() => import('./recon/ReconciliationDetail'), 5)
);
const ReconciliationDashboard = lazy(() =>
    componentLoader(() => import('./recon2/pages/Dashboard'), 5)
);
const ReconciliationResults = lazy(() =>
    componentLoader(() => import('./recon2/pages/Results'), 5)
);
const ReconciliationSummary = lazy(() =>
    componentLoader(() => import('./recon2/pages/TransactionSummary'), 5)
);
const ReconciliationSettlement = lazy(() =>
    componentLoader(() => import('./recon2/pages/SettlementList'), 5)
);
const ReconciliationSettlementReport = lazy(() =>
    componentLoader(() => import('./recon2/pages/SettlementReport'), 5)
);
const ReconciliationExceptions = lazy(() =>
    componentLoader(() => import('./recon2/pages/Exceptions'), 5)
);
const Modal = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: "modal" */ '../components/layout/Modal'),
    5
  )
);

const roboto = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${RobotoWoff2}) format('woff2')
  `,
};

// Setup base colors for Meterial UI
const theme = createTheme({
  palette: {
    primary: {
      main: '#0098d8',
    },
    secondary: {
      main: '#01c1fe',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [roboto],
      },
    },
    MuiTableCell: {
      root: { width: 'auto !important' },
    },
  },
});

const App = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [modalOpen, toggleModal] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleLogout = () => {
    props.keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  };

  const handleOnIdle = (event) => {
    toggleModal(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  useEffect(() => {
    ReactGA.initialize(
      window?._env_?.GA_ID ? window._env_.GA_ID : 'G-8N32PSYH68'
    );
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname === '/' ? '/dashboard' : location.pathname,
      title: location.pathname === '/' ? '/dashboard' : location.pathname,
    });
  }, [location]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname === '/' ? '/dashboard' : location.pathname,
      title: location.pathname === '/' ? '/dashboard' : location.pathname,
    });
  }, [location]);

  useEffect(() => {
    if (_.isEmpty(props.userInfo.data)) {
      props.keycloak.loadUserInfo().then((user) => {
        dispatch(thunk_fetch_user_info(user.preferred_username));
      });
    }

    if (props.location.search) {
      let baseURI = window.location.href.substring(
        window.location.href.indexOf('=') + 1
      );
      baseURI = decodeURIComponent(baseURI);
      props.history.push(baseURI);
    }

    if (!_.isEmpty(props.userInfo.data)) {
      setLoading(false);
      if (
        !window.location.pathname.includes('/transactions') &&
        !window.location.pathname.includes('/notifications') &&
        !window.location.pathname.includes('/reconciliation') &&
        !window.location.pathname.includes('/reports') &&
        !window.location.pathname.includes('/help') &&
        !window.location.pathname.includes('/')
      ) {
        history.push('/');
      }
    }
  }, [props.userInfo]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (props.userInfo.isError) {
      return <NetworkError customMessage="user info could not be loaded" />;
    }

    return (
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="container">
            <Header keycloak={props.keycloak} />
            <Navigation />
            <main className="content">
              {modalOpen && (
                <Modal
                  autoOpen
                  size="xs"
                  title="Session Timeout due to inactivity"
                  modalOpen={modalOpen}
                  childOne={
                    <div>
                      Your session expired due to inactivity. Please login again
                      to continue using Transpector.
                    </div>
                  }
                  onModalClose={() => {
                    toggleModal(false);
                    dispatch(handleLogout());
                  }}
                />
              )}
              <Switch>
                <DashboardRoute
                  exact
                  path="/"
                  roles={props.userInfo.data.roles}
                />
                <Route
                  exact
                  path="/transactions"
                  component={(props) => <TransactionsPage />}
                />
                <Route
                  exact
                  path="/notifications"
                  component={(props) => <NotificationPage />}
                />
                <Route
                  exact
                  path="/reconciliation"
                  component={(props) => <ReconciliationPage />}
                />
                <Route
                  exact
                  path="/reconciliation/detail"
                  component={(props) => <ReconciliationDetail />}
                />
                <Route
                    exact
                    path="/reconciliation/dashboard"
                    component={(props) => <ReconciliationDashboard />}
                />
                <Route
                    exact
                    path="/reconciliation/results"
                    component={(props) => <ReconciliationResults />}
                />
                <Route
                    exact
                    path="/reconciliation/results/summary"
                    component={(props) => <ReconciliationSummary />}
                />
                <Route
                    exact
                    path="/reconciliation/settlement"
                    component={(props) => <ReconciliationSettlement />}
                />
                <Route
                    exact
                    path="/reconciliation/settlement/report"
                    component={(props) => <ReconciliationSettlementReport />}
                />
                <Route
                    exact
                    path="/reconciliation/exception"
                    component={(props) => <ReconciliationExceptions />}
                />
                <Route
                  exact
                  path="/reports"
                  component={(props) => <Redirect to="/reports/transactions" />}
                />
                <Route
                  exact
                  path="/reports/transactions"
                  component={(props) => <TransactionReports />}
                />
                <Route
                  exact
                  path="/reports/recon"
                  component={(props) => <ReconReports />}
                />
                <Route exact path="/help" component={(props) => <HelpPage />} />
                <Route path="/*" component={Page404} />
              </Switch>
            </main>
            <Footer />
          </div>
        </ThemeProvider>
        <ReduxToastr
          timeOut={8000}
          newestOnTop
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Suspense>
    );
  };

  return renderContent();
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};

export default withRouter(connect(mapStateToProps)(App));
