import { SAVING_SEARCH_CRITERIA, SAVE_SEARCH_CRITERIA, SAVE_SEARCH_CRITERIA_ERROR } from '../actions/actionsTypes';

const initialState = {
  saved: false,
  isSaving: false,
  isError: false
};

const saveSearchCriteriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVING_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        isSaving: true,
        isError: false,
        saved: false
      });
    case SAVE_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        isSaving: false,
        isError: false,
        saved: true
      });
    case SAVE_SEARCH_CRITERIA_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isSaving: false,
        saved: false
      });
    default:
      return state;
  }
};

export default saveSearchCriteriaReducer;