import { FETCH_METRICS_DATA, RECEIVED_METRICS_DATA, FETCH_METRICS_DATA_ERROR } from '../actions/actionsTypes';

const initialState = {
  data: [],
  isFetching: false,
  isError: false
};

const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METRICS_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        data: [],
        isError: false
      });
    case RECEIVED_METRICS_DATA:
      return Object.assign({}, state, {
        data: action.data,
        isFetching: false,
        isError: false
      });
    case FETCH_METRICS_DATA_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false
      });
    default:
      return state;
  }
};

export default metricsReducer;