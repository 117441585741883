// External Libraries
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

// Reducers
import userReducer from './userReducer';
import reconReducer from './reconReducer';
import storesReducer from './storesReducer';
import filterReducer from './filterReducer';
import lookupReducer from './lookupReducer';
import metricsReducer from './metricsReducer';
import reportsReducer from './reportsReducer';
import retailerReducer from './retailerReducer';
import storeIdsReducer from './storeIdsReducer';
import storeNameReducer from './storeNameReducer';
import graphDataReducer from './graphDataReducer';
import exportDataReducer from './exportDataReducer';
import activeStoreReducer from './activeStoreReducer';
import transactionsReducer from './transactionsReducer';
import savedSearchesReducer from './savedSearchesReducer';
import notificationsReducer from './notificationsReducer';
import downloadReportReducer from './downloadReportReducer';
import searchCriteriaReducer from './searchCriteriaReducer';
import transactionTypeReducer from './transactionTypeReducer';
import saveSearchCriteriaReducer from './saveSearchCriteriaReducer';
import transactionOutcomeReducer from './transactionOutcomeReducer';
import savedSearchCriteriaReducer from './savedSearchCriteriaReducer';
import inactiveTerminalCountReducer from './inactiveTerminalCountReducer';

/*****************************************
Combine all reducers into one state object
******************************************/
const rootReducer = combineReducers({
  userInfo: userReducer,
  toastr: toastrReducer,
  stores: storesReducer,
  lookups: lookupReducer,
  filters: filterReducer,
  metrics: metricsReducer,
  reports: reportsReducer,
  storeIds: storeIdsReducer,
  retailers: retailerReducer,
  reconData: reconReducer,
  graphData: graphDataReducer,
  storeNames: storeNameReducer,
  report: downloadReportReducer,
  activeStore: activeStoreReducer,
  exportFilters: exportDataReducer,
  transactions: transactionsReducer,
  savedSearches: savedSearchesReducer,
  notifications: notificationsReducer,
  searchCriteria: searchCriteriaReducer,
  transactionTypes: transactionTypeReducer,
  saveSearchCriteria: saveSearchCriteriaReducer,
  transactionOutcomes: transactionOutcomeReducer,
  savedSearchCriteria: savedSearchCriteriaReducer,
  inactiveTerminalCount: inactiveTerminalCountReducer
});

export default rootReducer;
