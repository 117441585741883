import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import App from './components/App';

import api from './helpers/api';
import reconApi from './helpers/reconApi';
import recon2Api from './helpers/recon2Api';
import Keycloak from 'keycloak-js';
// Include Redux
import Redux from './Redux';
import { BrowserRouter } from 'react-router-dom';
import { updateUserAttributes } from './helpers/keycloakApi';

// Setup KeyCloak and Config variables
let keycloak = new Keycloak({
  realm: window?._env_?.KEYCLOAK_REALM ? window._env_.KEYCLOAK_REALM : 'Imbeko',
  url: window?._env_?.KEYCLOAK_URI
    ? window._env_.KEYCLOAK_URI
    : 'https://dev-sso.imbeko.live',
  clientId: window?._env_?.KEYCLOAK_CLIENT_ID
    ? window._env_.KEYCLOAK_CLIENT_ID
    : 'transpector-ui',
});

//Initialization of the keycloak instance
keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .then(async (authenticated) => {
    if (!authenticated) {
      window.location.reload();
    }

    const profile = await keycloak.loadUserProfile();
    if (
      profile?.attributes?.hasNewTheme &&
      profile?.attributes?.hasNewTheme[0] === 'true'
    ) {
      return window.location.replace(window?._env_?.TRANSPECTOR_LINK);
    }

    //store authentication tokens in sessionStorage for usage in app
    sessionStorage.setItem('authentication', keycloak.token);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

    api.defaults.headers = { Authorization: 'Bearer ' + keycloak.token };
    reconApi.defaults.headers = { Authorization: 'Bearer ' + keycloak.token };
    recon2Api.defaults.headers = { Authorization: 'Bearer ' + keycloak.token };

    ReactDOM.render(
      <Redux>
        <BrowserRouter>
          <App keycloak={keycloak} />
        </BrowserRouter>
      </Redux>,
      document.getElementById('root')
    );
    //to regenerate token on expiry
    setInterval(() => {
      keycloak
        .updateToken(120)
        .then((refreshed) => {
          if (refreshed) {
            sessionStorage.setItem('authentication', keycloak.token);
            sessionStorage.setItem('refreshToken', keycloak.refreshToken);

            api.defaults.headers = {
              Authorization: 'Bearer ' + keycloak.token,
            };
            reconApi.defaults.headers = {
              Authorization: 'Bearer ' + keycloak.token,
            };
            console.debug('Token refreshed' + refreshed);
          } else {
            console.warn(
              'Token not refreshed, valid for ' +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                ' seconds'
            );
          }
        })
        .catch((error) => {
          console.error('Failed to refresh token');
        });
    }, 60000);
  })
  .catch((error) => {
    console.error('Authenticated Failed');
  });
