import React from 'react';
import { useSelector } from 'react-redux';
import {
  faFilter,
  faInfoCircle,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HelpPage = () => {
  const user = useSelector((state) => state.userInfo);

  const contact = (
    <div className="contact-icons">
      <h3>Contact us for assistance</h3>
      <p>
        <i className="icon fa fa-at" />
        &nbsp;&nbsp;support@switch.tj
      </p>
      <p>
        <i className="icon fa fa-phone" />
        &nbsp;&nbsp;+27 86 111 4958
      </p>
    </div>
  );

  if (user.data.roles.includes('store')) {
    return (
      <div className="help-text-container">
        <p className="help-welcome-paragraph">Welcome to Transpector!</p>
        <p>
          This portal enables you to manage your transaction landscape with
          Transaction Junction. You will be able to see statistics regarding
          transactions processed in the last 12 to 48 hours in your environment,
          query transactions based on a wide range of criteria, access and
          download reports, and be notified of system-wide events that may
          affect transaction processing.
        </p>
        <h3>Dashboard</h3>
        <p>
          After logging in, the first page displayed is the Dashboard. A list of
          transactions that have failed over the last 24 hours is visible on the
          left-hand side. Transactions in the list can be sorted based on time,
          terminal ID, transaction type and amount. Columns that can be sorted
          have <FontAwesomeIcon icon={faArrowUp} /> or{' '}
          <FontAwesomeIcon icon={faArrowDown} /> icon in the header. Click on
          the header to sort. Furthermore, filtering of these transactions by
          transaction type is available. Filtering is selectable using a
          dropdown, where a specific value can be selected, or a key-entered
          value, indicated by <FontAwesomeIcon icon={faFilter} />
          icon and a text area under the column header.
        </p>
        <p>
          On the right-hand side of the Dashboard are some metrics relating to
          transaction processing and a graph covering transaction volumes over a
          selected period, transaction type, and transaction outcomes. This is
          viewable as a percentage-based increase or decrease over the period.
        </p>
        <h3>Transactions</h3>
        <p>
          The Transactions page allows for detailed transaction searching, with
          an emphasis on date ranges. Once a date range is selected, a list of
          transactions is provided, where further selection criteria can be used
          to narrow the search using filters, available on all columns except
          amount and date. Sorting on a number of columns is also available.
        </p>
        <p>
          Once a transaction of interest has been found, clicking on the{' '}
          <FontAwesomeIcon icon={faInfoCircle} /> icon will pop up a detail page
          for the transaction. From here a support request can be logged by
          clicking the “log ticket” button. Responses to your support request
          will be sent to you via email.
        </p>
        <h3>Reports</h3>
        <p>
          The Reports page allows you to download reports, based on a date
          range. Select the report of interest and click to download. These
          reports would typically include analysis/recon results, transaction
          and exceptions dependent on the client and provider.
        </p>
        <h3>Notifications</h3>
        <p>
          When a system-wide issue, for example a network outage or a problem
          with one of our transaction authorisers or reports, is identified by
          Transaction, a notification will be posted. This notification will
          become visible on the Notifications page. The notification will be
          removed once the issue has been resolved.
        </p>
        <h3>Training Video</h3>
        <a
          href="https://indd.adobe.com/view/4d6280dd-4cc3-4ad2-9edb-7c489b2ac9e1"
          target="blank"
        >
          Transpector 5 Training_Generic
        </a>
        {contact}
      </div>
    );
  }
  return (
    <div className="help-text-container">
      <p className="help-welcome-paragraph">Welcome to Transpector!</p>
      <p>
        This portal enables you to manage your transaction landscape with
        Transaction Junction. You will be able to see statistics regarding
        transactions processed in the last 12 to 48 hours in your environment,
        query transactions based on a wide range of criteria, access and
        download reports, and be notified of system-wide events that may affect
        transaction processing.
      </p>
      <h3>Dashboard</h3>
      <p>
        After logging in, the first page displayed is the Dashboard. A list of
        transactions that have failed over the last 24 hours is visible on the
        left-hand side. Transactions in the list can be sorted based on time,
        terminal ID, transaction type and amount. Columns that can be sorted
        have <FontAwesomeIcon icon={faArrowUp} /> or{' '}
        <FontAwesomeIcon icon={faArrowDown} /> icon in the header. Click on the
        header to sort. Furthermore, filtering of these transactions by
        transaction type is available. Filtering is selectable using a dropdown,
        where a specific value can be selected, or a key-entered value,
        indicated by <FontAwesomeIcon icon={faFilter} />
        icon and a text area under the column header.
      </p>
      <p>
        On the right-hand side of the Dashboard are some metrics relating to
        transaction processing and a graph covering transaction volumes over a
        selected period, transaction type, and transaction outcomes. This is
        viewable as a percentage-based increase or decrease over the period.
      </p>
      <h3>Transactions</h3>
      <p>
        The Transactions page allows for detailed transaction searching, with an
        emphasis on date ranges. Once a date range is selected, a list of
        transactions is provided, where further selection criteria can be used
        to narrow the search using filters, available on all columns except
        amount and date. Sorting on a number of columns is also available.
      </p>
      <p>
        Once a transaction of interest has been found, clicking on the{' '}
        <FontAwesomeIcon icon={faInfoCircle} /> icon will pop up a detail page
        for the transaction. From here a support request can be logged by
        clicking the “log ticket” button. Responses to your support request will
        be sent to you via email.
      </p>
      <h3>Reports</h3>
      <p>
        The Reports page allows you to download reports, based on a date range.
        Select the report of interest and click to download. These reports would
        typically include analysis/recon results, transaction and exceptions
        dependent on the client and provider.
      </p>
      <h3>Notifications</h3>
      <p>
        When a system-wide issue, for example a network outage or a problem with
        one of our transaction authorisers or reports, is identified by
        Transaction, a notification will be posted. This notification will
        become visible on the Notifications page. The notification will be
        removed once the issue has been resolved.
      </p>
      <h3>Training Video</h3>
      <a
        href="https://indd.adobe.com/view/4d6280dd-4cc3-4ad2-9edb-7c489b2ac9e1"
        target="blank"
      >
        Transpector 5 Training_Generic
      </a>
      {contact}
    </div>
  );
};

export default HelpPage;
