import { FETCH_REPORTS, RECEIVED_REPORTS, FETCH_REPORTS_ERROR, CLEAR_REPORTS } from '../actions/actionsTypes';

const initialState = {
  data: {},
  isFetching: false,
  isError: false
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS:
      return Object.assign({}, state, {
        isFetching: true,
        data: {},
        isError: false
      });
    case RECEIVED_REPORTS:
      return Object.assign({}, state, {
        data: action.data,
        isFetching: false,
        isError: false
      });
    case FETCH_REPORTS_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false
      });
    case CLEAR_REPORTS:
      return Object.assign({}, state, {
        isFetching: false,
        data: {},
        isError: false
      });
    default:
      return state;
  }
};

export default reportsReducer;